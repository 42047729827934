import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class BusinessInterruptionInsurance extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Business Interruption Insurance - Baton Rouge | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "No matter what industry or size your Louisiana based business may be, nothing should stand in the way of your ability to deliver products or services to your customers. If an interruption occurs, business insurance can help you get back up and running.",
            },
          ]}
        />
        <MainBg
          mainBg={this.props.data.mainBg}
          imgStyle={{ objectPosition: "top" }}
          alt="Employee writing down business information"
        />
        <Callout
          title="Business Interruption Insurance"
          content="No matter what industry or size your Louisiana based business may be, nothing should stand in the way of your ability to deliver your products, services and expertise to your customers. However, unforeseen events such as fire or wind damage can shutter your doors when you least expect it and bring your business to a standstill. And if an interruption occurs, you should be able to rely on a strong business continuity plan and insurance to help you get back up and running."
        >
          <div className="servicesVideoWrapper">
            <div className="videoWrapper">
              <iframe
                className="iframe services-video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/PxXx_K1AXTE?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Business Interruption Insurance: What You Need to Know"
              />
            </div>
          </div>
        </Callout>
        <div className="site-body page-content">
          <h2>What Business Interruption Insurance Usually Covers</h2>
          <p>
            <ul>
              <li>
                <strong>Revenue.</strong> This is the income your business would
                have earned during the closure period if it were operating
                normally.
              </li>
              <li>
                <strong>Rent or lease payments.</strong> Many leases require the
                renter to continue making payments even if the premises become
                unusable. Most also exclude renters from any insurance a
                landlord has, so you need to rely on your own coverage.
              </li>
              <li>
                <strong>Relocation.</strong> This is the expense of moving your
                business to a temporary location and may include both moving and
                rent costs.
              </li>
              <li>
                <strong>Employee wages.</strong> This is essential if you don’t
                want to lose employees while you’re shut down. Your coverage can
                help you make payroll when you’re unable to operate.
              </li>
              <li>
                <strong>Taxes.</strong> The taxman doesn’t stop collecting, even
                when disaster hits. This can help you pay your taxes on time.
              </li>
              <li>
                <strong>Loan payments.</strong> Loan payments are often due
                monthly, so your coverage can help you make those payments even
                when you can’t generate income.
              </li>
              <li>
                <strong>
                  Losses caused by covered damage that prevents access to a
                  building.
                </strong>{" "}
                For example, this coverage can help out when the government
                implements a citywide curfew or other restrictions that keep
                people away from your business. This is usually called “civil
                authority coverage” or “loss of ingress or egress.”
              </li>
            </ul>
          </p>
          <DidYouKnow
            fact="40% of businesses fail to re-open after a disaster due to not having or miscalculation business interruption insurance."
            source="United States Small Business Administration"
          />
          <h2>What Business Interruption Insurance Usually Doesn't Cover</h2>
          <p>
            <ul>
              <li>
                <strong>Utilities.</strong> In most cases, utilities aren’t
                covered because utility service typically shuts off when the
                location can’t be used for business.
              </li>
              <li>
                <strong>Income that isn’t documented.</strong> If your business
                is steadily growing, it’s important to document several months
                of revenue so the insurance company can compensate you
                adequately. You won’t be reimbursed for income that’s
                unaccounted for.
              </li>
              <li>
                <strong>Losses from partial closures.</strong> If access to your
                business is limited but not completely eliminated by property
                damage, you usually can’t receive Business Interruption
                benefits. For example, say a windstorm causes a tree to fall
                onto your business’s stockroom. While your stockroom’s roof
                needs repairs, you’re still able operate and remain open for
                customers.
              </li>
              <li>
                <strong>
                  Losses from closures caused by non-covered damages.
                </strong>{" "}
                As mentioned earlier, most Property Insurance policies don’t
                cover flood damage. The same goes for earthquake damage. And
                remember, you can’t receive income coverage when you voluntarily
                close (e.g., for a family emergency or vacation).
              </li>
              <li>
                <strong>Closures from downed power lines.</strong> If a storm or
                accident results in downed power lines, most closures are not
                covered. Power outages are a common occurrence, making it a
                difficult risk to insure because it affects so many people.
                Power is typically restored quickly, and most Business
                Interruption policies require a business to be shut down for at
                least 72 hours before benefits kick in.
              </li>
            </ul>
          </p>
        </div>
        <CallToAction
          bg={this.props.data.callToActionBg}
          type="a Business Interruption Insurance"
        />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default BusinessInterruptionInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "business-interruption-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-5.jpg" }) {
      ...mainBg
    }
  }
`;
